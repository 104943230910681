import { Button, Divider, LinearProgress } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import classNames from 'classnames'
import BtnVerifyEmail from 'components/BtnVerifyEmail'
import { SessionContext } from 'contexts/SessionContext'
import { EUserDetailStatus } from 'models'
import React, { ReactNode, useContext } from 'react'
import { Bookmark } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import FormatHelper from 'utils/FormatHelper'
import styles from './paperStyle-jss'

type PaperBlockProps = {
  title: string
  desc: string
  // icon?: FC<IconProps>
  icon?: ReactNode
  whiteBg?: boolean
  colorMode?: boolean
  noMargin?: boolean
  overflowX?: boolean
  ext?: ReactNode
  loading?: boolean
}

const PaperBlock: React.SFC<PaperBlockProps> = props => {
  const {
    title,
    desc,
    children,
    whiteBg = true,
    noMargin,
    colorMode,
    overflowX,
    icon,
    ext,
    loading,
    // icon = Bookmark,
  } = props
  const classes = useStyles()
  const [session] = useContext(SessionContext)
  if (!session.user) return null
  const { user } = session
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div>
      <Paper className={classNames(classes.root, noMargin && classes.noMargin, colorMode && classes.colorMode)} elevation={0}>
        <div className={classes.headerBlock}>
          <div className={classes.descBlock}>
            {icon ? (
              <span className={classes.iconTitle} style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                {icon}
              </span>
            ) : (
              <span className={classes.iconTitle}>
                <Bookmark />
              </span>
            )}
            <div className={classes.titleText}>
              <Typography variant="h6" component="h2" className={classes.title}>
                {title}
              </Typography>
              <Typography component="p" className={classes.description}>
                {desc}
              </Typography>
            </div>
          </div>
          {ext && <div className={classes.extBlock}>{ext}</div>}
        </div>
        {loading ? <LinearProgress /> : <Divider style={{ margin: '12px 0' }} />}

        <div style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', padding: '13px' }}>
          {!user.verifiedTime && !window.location.pathname.startsWith('/profile') && !window.location.pathname.startsWith('/kyc') ? (
            <Paper style={{ width: user.userDetail?.status === EUserDetailStatus.APPROVED ? '100%' : 'inherit' }}>
              <Alert icon={<IconEMail />} variant="filled" style={{ justifyContent: 'center', alignItems: 'center', background: 'transparent' }}>
                <span style={{ color: 'black' }}>{t('errors.email_not_verified')}</span>
                <span style={{ marginLeft: 12 }}>
                  <BtnVerifyEmail />
                </span>
              </Alert>
            </Paper>
          ) : (
            <></>
          )}

          <div style={{ marginLeft: 5, marginRight: 5 }}></div>

          {user.userDetail?.status !== EUserDetailStatus.APPROVED &&
          !window.location.pathname.startsWith('/profile') &&
          !window.location.pathname.startsWith('/kyc') ? (
            <Paper style={{ width: user.verifiedTime ? '100%' : 'inherit' }}>
              <Alert
                icon={<IconKYC status={user.userDetail?.status} />}
                variant="filled"
                style={{ justifyContent: 'center', alignItems: 'center', background: 'transparent' }}
              >
                <span style={{ color: 'black' }}>
                  <>
                    {user.userDetail?.status === EUserDetailStatus.REJECTED ? (
                      <>
                        Your data has been <b style={{ color: '#CE152A' }}>{FormatHelper.capitalize(user.userDetail?.status)}</b>. Reason :{' '}
                        <b>{user.userDetail?.notes}</b>
                      </>
                    ) : user.userDetail?.status === EUserDetailStatus.REVIEW ? (
                      <>Your data has been recorded in our system, Please wait, our team will verify your data and will notify to you as soon as possible.</>
                    ) : (
                      <>Dear Valued Customer, we need to verify your account as compliance requirement to ensure your transaction are secure</>
                    )}
                  </>
                </span>
                <span style={{ marginLeft: 12 }}>
                  {user.userDetail?.status === EUserDetailStatus.REJECTED ? (
                    <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={() => history.replace('/kyc', { hide: true })}>
                      Resubmit Data
                    </Button>
                  ) : user.userDetail?.status === EUserDetailStatus.REVIEW ? (
                    <span style={{ color: 'orange', fontWeight: 'bold' }}>Waiting for review</span>
                  ) : (
                    <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={() => history.replace('/kyc')}>
                      Get Started
                    </Button>
                  )}
                </span>
              </Alert>
            </Paper>
          ) : null}
        </div>
        <section className={classNames(classes.content, whiteBg && classes.whiteBg, overflowX && classes.overflowX)}>{children}</section>
      </Paper>
    </div>
  )
}

const IconKYC: React.FC<{ status?: EUserDetailStatus }> = ({ status }) => (
  <img
    src={
      status === EUserDetailStatus.REJECTED
        ? '/static/images/icon_kyc_rejected.png'
        : status === EUserDetailStatus.REVIEW
        ? '/static/images/icon_kyc_submitted.png'
        : '/static/images/kyc-icon.png'
    }
    alt="user_detail_icon"
    height={50}
    width={50}
    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
  />
)

const IconEMail = () => (
  <img
    src="/static/images/icon_mail_notif.png"
    alt="user_detail_icon"
    height={50}
    width={50}
    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
  />
)

//@ts-ignore
const useStyles = makeStyles(styles)

export default PaperBlock
