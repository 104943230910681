import { Partner, Currency } from './'

export class PartnerCurrency {
  constructor(
    public id: number,
    public partnerId: string,
    public currencyId: string,
    public accountEnabled: boolean,
    public exchangeEnabled: boolean,
    public transferEnabled: boolean,
    public withdrawEnabled: boolean,
    public exchangeMin: number,
    public exchangeMax: number,
    public transferMin: number,
    public transferMax: number,
    public withdrawMin: number,
    public withdrawMax: number,
    public partner?: Partner,
    public currency?: Currency,
  ) {}
}
