import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'contexts'
import { Account } from 'models'
import { SessionContext } from 'contexts/SessionContext'

interface DepositContentProps {
  account: Account
}

const DepositContent: React.SFC<DepositContentProps> = ({}) => {
  const [session] = useContext(SessionContext)

  const { t } = useTranslation('wallet')

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            width: '90%',
            padding: '15px',
            textAlign: 'center',
            margin: '0 auto',
          }}
        >
          <p>{t('bankNote.p1')}</p>
          <p>
            <strong>{t('bankNote.p2')}</strong>
          </p>
          <p style={{ marginTop: 30 }}>{t('bankNote.p3')}</p>
          <p>
            <strong>{t('bankNote.accountNumber')}</strong>
          </p>
          <p style={{ marginTop: 30 }}>{t('bankNote.p4')}</p>
          <p>
            <strong>{session.user && session.user.id}</strong>.
          </p>
          {/* <PaymentGatewayForm email={userEmail} lang={this.props.context.lang} currentLang={currentLang} /> */}
        </div>
      </Grid>
    </Grid>
  )
}

export default DepositContent
