import { Box, Button, CircularProgress, FormControl, FormGroup, Grid, Switch, TextField, Typography } from '@material-ui/core'
import Loading from 'components/Loading/Loading'
import { AppContext } from 'contexts'
import lodash, { isEmpty } from 'lodash'
import { Address, City, District, EUserDetailNationality, Province, UserDetail } from 'models'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Api from 'utils/Api'
import { CustomSelect, IStepContentProps } from './KYCFormWrapper'
import { useStyles } from './KYCPage'

export const KYCAddress: React.FC<IStepContentProps> = ({ currentUser, handleNext, handleBack }) => {
  const history = useHistory()
  const { handleSubmit } = useForm()
  const { handleError } = useContext(AppContext)
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<Partial<UserDetail>>({
    id: undefined,
    userId: undefined,
    status: undefined,
    identityAddressId: undefined,
    currentAddressId: undefined,
  })
  const [address, setAddress] = useState<Partial<Address>>({
    id: undefined,
    countryId: 'IDN',
    provinceId: undefined,
    cityId: undefined,
    districtId: undefined,
    postalCode: undefined,
    detail: undefined,
  })
  const [currentAddress, setCurrentAddress] = useState<Partial<Address>>({
    id: undefined,
    countryId: 'IDN',
    provinceId: undefined,
    cityId: undefined,
    districtId: undefined,
    postalCode: undefined,
    detail: undefined,
  })

  const [saving, setSaving] = useState<boolean>(false)
  const [provinces, setProvinces] = useState<Province[]>()
  const [cities, setCities] = useState<City[]>()
  const [districts, setDistricts] = useState<District[]>()
  const [postalCodes, setPostalCodes] = useState<string[]>()
  const [currentProvinces, setCurrentProvinces] = useState<Province[]>()
  const [currentCities, setCurrentCities] = useState<City[]>()
  const [currentDistricts, setCurrentDistricts] = useState<District[]>()
  const [currentPostalCodes, setCurrentPostalCodes] = useState<string[]>()
  const [sameAddress, setSameAddress] = useState<boolean>(false)

  useEffect(() => {
    Api.request<UserDetail>({
      method: 'GET',
      url: `/user-details/${currentUser?.id}`,
    }).then(resp => {
      if (resp.data as UserDetail) {
        setData({
          id: resp.data.id,
          userId: resp.data.userId,
          status: resp.data.status,
          identityAddressId: resp.data.identityAddressId,
          currentAddressId: resp.data.currentAddressId,
        })

        if (resp.data.identityAddress) {
          setAddress({
            id: resp.data.identityAddress.id,
            countryId: resp.data.identityAddress.countryId,
            provinceId: resp.data.identityAddress.provinceId,
            cityId: resp.data.identityAddress.cityId,
            districtId: resp.data.identityAddress.districtId,
            postalCode: resp.data.identityAddress.postalCode,
            detail: resp.data.identityAddress.detail,
          })

          if (resp.data.identityAddress.countryId) {
            if (resp.data.identityAddress.countryId !== 'IDN') {
              Api.get(`/provinces?filter={"where": {"countryId": "OTH"}}`).then(resp => {
                setProvinces(resp.data)
                setCurrentProvinces(resp.data)
              })
            } else {
              Api.get(`/provinces?filter={"where": {"countryId": "IDN"}}`).then(resp => {
                setProvinces(resp.data)
                setCurrentProvinces(resp.data)
              })
            }
          } else {
            if (resp.data.nationality !== EUserDetailNationality.INDONESIA) {
              Api.get(`/provinces?filter={"where": {"countryId": "OTH"}}`).then(resp => {
                setProvinces(resp.data)
                setCurrentProvinces(resp.data)
              })
            } else {
              Api.get(`/provinces?filter={"where": {"countryId": "IDN"}}`).then(resp => {
                setProvinces(resp.data)
                setCurrentProvinces(resp.data)
              })
            }
          }

          const addrProvinceId = resp.data.identityAddress?.provinceId
          const addrCityId = resp.data.identityAddress?.cityId
          const addrDistrictId = resp.data.identityAddress?.districtId

          if (addrProvinceId) {
            Api.get<City[]>(`/cities?filter={"where": { "provinceId": ${addrProvinceId} } }`).then(c => {
              setCities(c.data)
              if (addrCityId) {
                Api.get<District[]>(`/districts?filter={"where": {"cityId": ${addrCityId} } }`).then(d => {
                  setDistricts(d.data)
                  if (addrDistrictId) {
                    Api.get<District>(`/district/${addrDistrictId}/postalcodes`).then(p => {
                      setPostalCodes(p.data.postalCodes)
                    })
                  }
                })
              }
            })
          }
        } else {
          Api.get(`/provinces?filter={"where": {"countryId": "IDN"}, "order": ["name ASC"]}`).then(resp => {
            setProvinces(resp.data)
          })
        }

        if (resp.data.currentAddress) {
          setCurrentAddress({
            id: resp.data.currentAddress.id,
            countryId: resp.data.currentAddress.countryId,
            provinceId: resp.data.currentAddress.provinceId,
            cityId: resp.data.currentAddress.cityId,
            districtId: resp.data.currentAddress.districtId,
            postalCode: resp.data.currentAddress.postalCode,
            detail: resp.data.currentAddress.detail,
          })

          const currProvinceId = resp.data.currentAddress?.provinceId
          const currCityId = resp.data.currentAddress?.cityId
          const currDistrictId = resp.data.currentAddress?.districtId

          if (currProvinceId) {
            Api.get<City[]>(`/cities?filter={"where": { "provinceId": ${currProvinceId} } }`).then(c => {
              setCurrentCities(c.data)
              if (currCityId) {
                Api.get<District[]>(`/districts?filter={"where": {"cityId": ${currCityId} } }`).then(d => {
                  setCurrentDistricts(d.data)
                  if (currDistrictId) {
                    Api.get<District>(`/district/${currDistrictId}/postalcodes`).then(p => {
                      setCurrentPostalCodes(p.data.postalCodes)
                    })
                  }
                })
              }
            })
          }
        } else {
          Api.get(`/provinces?filter={"where": {"countryId": "IDN"}, "order": ["name ASC"]}`).then(resp => {
            setCurrentProvinces(resp.data)
          })
        }
      }

      if (resp.data.identityAddress && resp.data.currentAddress) {
        if (
          lodash.every(lodash.omit(resp.data.identityAddress, 'id', 'countryId'), isEmpty) &&
          lodash.every(lodash.omit(resp.data.currentAddress, 'id', 'countryId'), isEmpty)
        ) {
          setSameAddress(false)
        } else {
          if (
            lodash.isEqual(
              lodash.pick(resp.data.identityAddress, 'countryId', 'provinceId', 'cityId', 'districtId', 'postalCode', 'detail'),
              lodash.pick(resp.data.currentAddress, 'countryId', 'provinceId', 'cityId', 'districtId', 'postalCode', 'detail'),
            )
          ) {
            setSameAddress(true)
          } else {
            setSameAddress(false)
          }
        }
      }

      setLoading(false)
    })
  }, [])

  const handleAddressChange = (key: keyof Address, value: any) => {
    setAddress({ ...address, [key]: value })
  }

  const handleCurrentAddressChange = (key: keyof Address, value: any) => {
    setCurrentAddress({ ...currentAddress, [key]: value })
  }

  const onSubmit = () => {
    setSaving(true)
    const payload = {
      id: data.id,
      userId: currentUser.id,
      identityAddress: {
        ...lodash.omit(address, 'country', 'province', 'city', 'district'),
      },
      currentAddress: {
        ...lodash.omit(currentAddress, 'country', 'province', 'city', 'district'),
      },
    }

    Api.patch(`/user-details/${data.id}`, payload)
      .then(() => {
        setSaving(false)
        handleNext()
      })
      .catch(err => {
        setSaving(false)
        handleError(err)
      })
  }

  if (address) {
    useEffect(() => {
      if (address.provinceId) {
        Api.get<City[]>(`/cities?filter={"where": { "provinceId": ${address.provinceId} } }`).then(cities => {
          setCities(cities.data)
        })
      }
    }, [address.provinceId])

    useEffect(() => {
      if (address.cityId) {
        Api.get<District[]>(`/districts?filter={"where": { "cityId": ${address.cityId} } }`).then(districts => {
          setDistricts(districts.data)
        })
      }
    }, [address.cityId])

    useEffect(() => {
      if (address.districtId) {
        Api.get<District>(`/district/${address.districtId}/postalcodes`).then(district => {
          setPostalCodes(district.data.postalCodes)
        })
      }
    }, [address.districtId])
  }

  if (currentAddress) {
    useEffect(() => {
      if (currentAddress.provinceId) {
        Api.get<City[]>(`/cities?filter={ "where": { "provinceId": ${currentAddress.provinceId} } }`).then(cities => {
          setCurrentCities(cities.data)
        })
      }
    }, [currentAddress.provinceId])

    useEffect(() => {
      if (currentAddress.cityId) {
        Api.get<District[]>(`/districts?filter={"where": { "cityId": ${currentAddress.cityId} } }`).then(districts => {
          setCurrentDistricts(districts.data)
        })
      }
    }, [currentAddress.cityId])

    useEffect(() => {
      if (currentAddress.districtId) {
        Api.get<District>(`/district/${currentAddress.districtId}/postalcodes`).then(district => {
          setCurrentPostalCodes(district.data.postalCodes)
        })
      }
    }, [currentAddress.districtId])
  }

  useEffect(() => {
    if (sameAddress) {
      setCurrentAddress(prevVal => ({
        ...prevVal,
        countryId: address.countryId,
        provinceId: address.provinceId,
        cityId: address.cityId,
        districtId: address.districtId,
        postalCode: address.postalCode,
        detail: address.detail,
      }))
    } else {
      setCurrentAddress(prevVal => ({
        ...prevVal,
        countryId: 'IDN',
        provinceId: undefined,
        cityId: undefined,
        districtId: undefined,
        postalCode: undefined,
        detail: '',
      }))
    }
  }, [sameAddress])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box>
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              Address <span style={{ fontWeight: 'normal', fontSize: '12px' }}>(Match the ID Card)</span>
            </Typography>
            <Box mt={5}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <FormGroup>
                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <FormControl fullWidth size="small">
                        <Grid container direction="row">
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.labelLeft}>Province</Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomSelect
                              required={true}
                              value={address?.provinceId}
                              defaultValue={address?.provinceId}
                              array={provinces}
                              onChange={e => handleAddressChange('provinceId', parseInt(e.target.value))}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <FormControl fullWidth size="small">
                        <Grid container direction="row">
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.labelLeft}>City / State</Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomSelect
                              required={true}
                              value={address?.cityId}
                              defaultValue={address?.cityId}
                              array={cities}
                              onChange={e => handleAddressChange('cityId', parseInt(e.target.value))}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <FormControl fullWidth size="small">
                        <Grid container direction="row">
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.labelRight}>District</Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomSelect
                              required={true}
                              value={address?.districtId}
                              defaultValue={address?.districtId}
                              array={districts}
                              onChange={e => handleAddressChange('districtId', parseInt(e.target.value))}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <FormControl fullWidth size="small">
                        <Grid container direction="row">
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.labelLeft}>Post Code</Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomSelect
                              required={true}
                              value={address?.postalCode}
                              defaultValue={address?.postalCode}
                              array={postalCodes}
                              type="postalCode"
                              onChange={e => handleAddressChange('postalCode', e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                      <FormControl fullWidth size="small" required>
                        <Grid container direction="row">
                          <Grid item xs={12} sm={2}>
                            <Typography className={classes.labelLeft}>Detail</Typography>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <TextField
                              fullWidth
                              multiline
                              minRows={4}
                              maxRows={4}
                              defaultValue={address?.detail}
                              value={address?.detail}
                              required
                              variant="outlined"
                              onChange={e => handleAddressChange('detail', e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              </form>
            </Box>
          </Box>
          <Box mt={4} display="flex" justifyContent="flex-end">
            <span>
              <u style={{ color: '#1598E2', cursor: 'pointer', userSelect: 'none' }} onClick={() => setSameAddress(!sameAddress)}>
                My current address is the same as my ID Card
              </u>
              <Switch checked={sameAddress} onChange={() => setSameAddress(!sameAddress)} />
            </span>
          </Box>
          <Box mt={3}>
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              Current Address
            </Typography>
            <Box mt={5}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <FormGroup>
                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <FormControl fullWidth size="small">
                        <Grid container direction="row">
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.labelLeft}>Province</Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomSelect
                              required={true}
                              value={currentAddress.provinceId ?? null}
                              defaultValue={currentAddress.provinceId ?? null}
                              array={currentProvinces}
                              onChange={e => handleCurrentAddressChange('provinceId', parseInt(e.target.value))}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <FormControl fullWidth size="small">
                        <Grid container direction="row">
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.labelLeft}>City / State</Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomSelect
                              required={true}
                              value={currentAddress.cityId ?? null}
                              defaultValue={currentAddress.cityId ?? null}
                              array={currentCities}
                              onChange={e => handleCurrentAddressChange('cityId', parseInt(e.target.value))}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <FormControl fullWidth size="small">
                        <Grid container direction="row">
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.labelRight}>District</Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomSelect
                              required={true}
                              value={currentAddress.districtId ?? null}
                              defaultValue={currentAddress.districtId ?? null}
                              array={currentDistricts}
                              onChange={e => handleCurrentAddressChange('districtId', parseInt(e.target.value))}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <FormControl fullWidth size="small">
                        <Grid container direction="row">
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.labelLeft}>Post Code</Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <CustomSelect
                              required={true}
                              value={currentAddress.postalCode ?? null}
                              defaultValue={currentAddress.postalCode ?? null}
                              array={currentPostalCodes}
                              type="postalCode"
                              onChange={e => handleCurrentAddressChange('postalCode', e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                      <FormControl fullWidth size="small">
                        <Grid container direction="row">
                          <Grid item xs={12} sm={2}>
                            <Typography className={classes.labelLeft}>Detail</Typography>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <TextField
                              fullWidth
                              multiline
                              minRows={4}
                              maxRows={4}
                              defaultValue={currentAddress?.detail}
                              value={currentAddress?.detail}
                              required
                              variant="outlined"
                              onChange={e => handleCurrentAddressChange('detail', e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={2}>
                      <Button variant="contained" size="small" className={classes.buttonOutlined} onClick={() => history.replace('/accounts')}>
                        Close
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={6}></Grid>
                    <Grid item xs={6} sm={4}>
                      {saving ? (
                        <CircularProgress />
                      ) : (
                        <Grid container>
                          <Grid item xs={8} sm={8} lg={9}>
                            <Box display="flex" justifyContent="flex-end">
                              <Button variant="contained" size="small" className={classes.buttonOutlined} onClick={handleBack}>
                                Previous
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4} lg={3}>
                            <Box display="flex" justifyContent="flex-end">
                              <Button type="submit" variant="contained" className={classes.buttonContained} size="small">
                                Next
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </FormGroup>
              </form>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
