import { Avatar, Box, Button, Chip, Dialog, DialogContent, Grid, Typography, makeStyles } from '@material-ui/core'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { SessionContext } from 'contexts/SessionContext'
import { UserStatus } from 'models'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import FormatHelper from 'utils/FormatHelper'
import { AccountInfo } from './AccountInfo'
import { ChangePassword } from './ChangePassword'
import { ProfileForm } from './ProfileForm'

export const ProfilePage: React.FunctionComponent<{}> = () => {
  const [session] = useContext(SessionContext)
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  if (!session.user) return null
  const { user } = session

  const [openAccountInfo, setOpenAccountInfo] = useState<boolean>(false)
  const [isProfileEditing, setIsProfileEditing] = useState<boolean>(false)

  return (
    <Page title={t('profile.my_profile')} padded>
      <PaperBlock title={t('profile.my_profile')} desc={t('profile.my_profile_subtitle')}>
        <Grid container>
          <Grid item xs={12} md={12} lg={2}>
            <Box alignItems="center" display="flex" flexDirection="column">
              <Avatar className={classes.avatar} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Box alignItems="flex-start" display="flex" flexDirection="column" p={4}>
              <Typography gutterBottom variant="h2">
                {user.name}
              </Typography>
              <Typography gutterBottom>
                {user.email}{' '}
                <Chip
                  size="small"
                  label={user.verifiedTime ? 'Verified' : 'Unverified'}
                  style={{ color: 'white', fontWeight: 'bold', backgroundColor: user.verifiedTime ? '#30A760' : 'grey' }}
                />
              </Typography>
              <Typography gutterBottom>User ID: {user.id}</Typography>
              <Chip
                label={FormatHelper.capitalize(user.status)}
                style={{ color: 'white', fontWeight: 'bold', backgroundColor: user.status === UserStatus.Active ? '#3699FF' : 'grey' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Box alignItems="flex-start" display="flex" flexDirection="column" p={4}>
              <Typography>
                Partner Name : <b>{user.partner?.name}</b>
              </Typography>
              <Box mt={1} />
              <Typography>Partner ID : {user.partner?.id}</Typography>
            </Box>
          </Grid>
        </Grid>

        <Box display="flex">
          <Button
            variant="outlined"
            onClick={() => {
              setOpenAccountInfo(!openAccountInfo)
            }}
          >
            {t('profile.account')}
          </Button>
          <div style={{ width: 10 }} />

          <ChangePassword />

          <div style={{ width: 10 }} />

          <Button
            variant="outlined"
            onClick={() => {
              history.push(`/profile/twofa`)
            }}
          >
            {t('profile.twofa')}
          </Button>

          <div style={{ width: 10 }} />

          <Button
            variant="outlined"
            onClick={() => {
              setIsProfileEditing(true)
            }}
          >
            {t('profile.edit_profile')}
          </Button>
        </Box>

        <Dialog
          open={isProfileEditing}
          onClose={() => {
            setIsProfileEditing(false)
          }}
        >
          <DialogContent>
            <ProfileForm
              user={user}
              onCancel={() => {
                setIsProfileEditing(false)
              }}
              onProfileUpdated={() => {
                setIsProfileEditing(false)
              }}
            />
          </DialogContent>
        </Dialog>

        <Box mt={5}>
          <AccountInfo user={user} openAccountInfo={openAccountInfo} />
        </Box>
      </PaperBlock>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    width: '156px',
    height: '157px',
    margin: theme.spacing(1),
  },
}))
