import { Box, Button, Grid, Typography } from '@material-ui/core'
import Loading from 'components/Loading/Loading'
import { EUserDetailStatus, UserDetail } from 'models'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'
import { IStepContentProps } from './KYCFormWrapper'
import { useStyles } from './KYCPage'

export const KYCSubmit: React.FC<IStepContentProps> = ({ currentUser }) => {
  const history = useHistory()
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(true)
  const [userDetail, setUserDetail] = useState<UserDetail>()

  useEffect(() => {
    Api.request<UserDetail>({
      method: 'GET',
      url: `/user-details/${currentUser?.id}`,
    }).then(resp => {
      if (resp.data as UserDetail) {
        setUserDetail(resp.data)
      }

      setLoading(false)
    })
  }, [])

  return (
    <Box display="flex" justifyContent="center" pt={5} pb={5}>
      {loading ? (
        <Loading />
      ) : (
        <Grid container direction="row">
          <Grid item xs={12}>
            <img
              src="/static/images/icon_kyc_submitted.png"
              alt="form_submited_icon"
              width={200}
              height={200}
              style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', marginBottom: 20 }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Typography align="center">
              {userDetail?.status === EUserDetailStatus.REVIEW ? (
                <>
                  Your data has been recorded in our system, <br /> Our team will verify your data.
                </>
              ) : userDetail?.status === EUserDetailStatus.APPROVED ? (
                <>Your data has been approved in our system.</>
              ) : null}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Typography align="center">
              <b>
                Status:{' '}
                <span
                  style={{
                    color:
                      userDetail?.status === EUserDetailStatus.APPROVED
                        ? '#30A760'
                        : userDetail?.status === EUserDetailStatus.REJECTED
                        ? '#CE152A'
                        : userDetail?.status === EUserDetailStatus.REVIEW
                        ? '#F6921E'
                        : 'grey',
                  }}
                >
                  {FormatHelper.capitalize(userDetail?.status as string)}
                </span>
              </b>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                size="small"
                style={{ width: '26%' }}
                className={classes.buttonContained}
                onClick={() => history.replace('/accounts')}
              >
                Back to Home
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
