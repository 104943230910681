import { User } from './User'

export class Files {
  constructor(
    public id: string,
    public userId: string,
    public shasum: string,
    public size: number,
    public mime: string,
    public url: string,
    public createdTime: Date,
    public user?: User,
  ) {}
}
