import DayjsUtils from '@date-io/dayjs'
import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Loading from 'components/Loading/Loading'
import { AppContext } from 'contexts'
import dayjs from 'dayjs'
import {
  EUserDetailGender,
  EUserDetailIdentityType,
  EUserDetailMaritalStatus,
  EUserDetailNationality,
  EUserDetailStatus,
  User,
  UserDetail,
  UserDetailPayload,
} from 'models'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'
import { CustomSelect, IStepContentProps } from './KYCFormWrapper'
import { useStyles } from './KYCPage'

export const KYCPersonalData: React.FC<IStepContentProps> = ({ currentUser, handleNext, handleBack }) => {
  const history = useHistory()
  const { handleSubmit } = useForm()
  const { handleError } = useContext(AppContext)
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<Partial<UserDetail>>({
    id: undefined,
    userId: currentUser.id,
    identityNumber: undefined,
    cityOfBirth: undefined,
    dateOfBirth: dayjs().subtract(17, 'year'),
    gender: EUserDetailGender.MALE,
    nationality: EUserDetailNationality.INDONESIA,
    identityType: EUserDetailIdentityType.KTP,
    maritalStatus: undefined,
    jobTitle: undefined,
  })
  const [user, setUser] = useState<Partial<User>>({
    name: currentUser.name,
    mobile: currentUser.mobile,
  })
  const [saving, setSaving] = useState<boolean>(false)

  useEffect(() => {
    Api.request<UserDetail>({
      method: 'GET',
      url: `/user-details/${currentUser?.id}`,
    }).then(resp => {
      if (resp.data as UserDetail) {
        setData({
          id: resp.data.id,
          userId: resp.data.userId ?? currentUser.id,
          identityNumber: resp.data.identityNumber,
          cityOfBirth: resp.data.cityOfBirth,
          dateOfBirth: resp.data.dateOfBirth ?? dayjs().subtract(17, 'year'),
          gender: resp.data.gender ?? EUserDetailGender.MALE,
          nationality: resp.data.nationality ?? EUserDetailNationality.INDONESIA,
          identityType: resp.data.identityType ?? EUserDetailIdentityType.KTP,
          maritalStatus: resp.data.maritalStatus,
          jobTitle: resp.data.jobTitle,
        })
        setUser({
          name: resp.data.user?.name,
          mobile: resp.data.user?.mobile,
        })
      }
      setLoading(false)
    })
  }, [])

  const handleDataChange = (key: keyof UserDetail, value: any) => {
    setData({
      ...data,
      [key]: value,
    })
  }

  const handleUserDataChange = (key: keyof User, value: any) => {
    setUser({
      ...user,
      [key]: value,
    })
  }

  const onSubmit = () => {
    setSaving(true)

    data.dateOfBirth = dayjs(data.dateOfBirth).format('YYYY-MM-DD')
    if (!data.id) {
      data.status = EUserDetailStatus.DRAFTED
    }

    const payload: Partial<UserDetailPayload> = {
      ...data,
    }

    Api.patch(`/users/${currentUser.id}`, user)
      .then(async () => {
        if (data.id) {
          Api.patch(`/user-details/${data.id}`, payload)
            .then(() => {
              setSaving(false)
              handleNext()
            })
            .catch(err => {
              setSaving(false)
              handleError(err)
            })
        } else {
          Api.post(`/user-details`, payload)
            .then(() => {
              setSaving(false)
              handleNext()
            })
            .catch(err => {
              setSaving(false)
              handleError(err)
            })
        }
      })
      .catch(err => {
        setSaving(false)
        handleError(err)
      })
  }

  useEffect(() => {
    if (data.nationality) {
      if (data.nationality === EUserDetailNationality.INDONESIA) {
        handleDataChange('identityType', EUserDetailIdentityType.KTP)
      } else {
        handleDataChange('identityType', EUserDetailIdentityType.PASSPORT)
      }
    }
  }, [data.nationality])

  return (
    <>
      <Typography variant="h4" style={{ fontWeight: 'bold' }}>
        Personal Data
      </Typography>
      <Typography variant="subtitle1">Make sure all the data matches your ID Card</Typography>
      {loading ? (
        <Loading />
      ) : (
        <Box mt={5}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Grid container direction="row">
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.gridItem}>
                  <FormControl fullWidth>
                    <Grid container direction="row">
                      <Grid item xs={12} lg={4}>
                        <Typography className={classes.labelRight}>Nationality</Typography>
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <RadioGroup
                          row
                          aria-label="nationality"
                          name="nationality"
                          value={data?.nationality}
                          onChange={e => {
                            handleDataChange('nationality', e.target.value)
                          }}
                        >
                          <FormControlLabel value={EUserDetailNationality.INDONESIA} control={<Radio />} label={EUserDetailNationality.INDONESIA} />
                          <FormControlLabel value={EUserDetailNationality.FOREIGNER} control={<Radio />} label={EUserDetailNationality.FOREIGNER} />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.gridItem}>
                  <FormControl fullWidth>
                    <Grid container direction="row">
                      <Grid item xs={12} sm={3} lg={2}>
                        <Typography className={classes.labelRight}>NIK / Passport</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={10}>
                        <TextField
                          fullWidth
                          placeholder="NIK / Passport"
                          variant="outlined"
                          size="small"
                          value={data?.identityNumber}
                          required={true}
                          onChange={e => {
                            if (e.target.value.length <= 16) {
                              handleDataChange('identityNumber', e.target.value)
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.gridItem}>
                  <FormControl fullWidth>
                    <Grid container direction="row">
                      <Grid item xs={12} sm={3} lg={2}>
                        <Typography className={classes.labelRight}>
                          Full Name <span style={{ fontSize: 11 }}>{'(As in Document)'}</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={10}>
                        <TextField
                          fullWidth
                          placeholder="Full Name"
                          variant="outlined"
                          size="small"
                          value={user?.name}
                          required={true}
                          onChange={e => handleUserDataChange('name', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.gridItem}>
                  <FormControl fullWidth>
                    <Grid container direction="row">
                      <Grid item xs={12} sm={3} lg={2}>
                        <Typography className={classes.labelRight}>Phone Number</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={10}>
                        <TextField
                          placeholder="Input Phone Number"
                          fullWidth
                          size="small"
                          variant="outlined"
                          type="number"
                          InputProps={{ classes: { input: classes.inputStyle } }}
                          value={user?.mobile}
                          required={true}
                          onChange={e => handleUserDataChange('mobile', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={6} className={classes.gridItem}>
                  <FormControl fullWidth>
                    <Grid container direction="row">
                      <Grid item xs={12} sm={3} lg={4}>
                        <Typography className={classes.labelRight}>City of Birth</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          placeholder="City of Birth"
                          variant="outlined"
                          size="small"
                          value={data?.cityOfBirth}
                          required={true}
                          onChange={e => handleDataChange('cityOfBirth', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={6} className={classes.gridItem}>
                  <FormControl fullWidth>
                    <Grid container direction="row">
                      <Grid item xs={12} sm={3} lg={4}>
                        <Typography className={classes.labelLeft}>Date of Birth</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={8}>
                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                          <DatePicker
                            size="small"
                            format="DD/MM/YYYY"
                            inputVariant="outlined"
                            fullWidth
                            value={data?.dateOfBirth}
                            placeholder="Select date of birth"
                            required={true}
                            onChange={e => {
                              handleDataChange('dateOfBirth', e)
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={6} className={classes.gridItem}>
                  <FormControl fullWidth>
                    <Grid container direction="row">
                      <Grid item xs={12} sm={3} lg={4}>
                        <Typography className={classes.labelRight}>Gender</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={8}>
                        <RadioGroup row aria-label="gender" name="gender" value={data?.gender} onChange={e => handleDataChange('gender', e.target.value)}>
                          <FormControlLabel value={EUserDetailGender.MALE} control={<Radio />} label={FormatHelper.capitalize(EUserDetailGender.MALE)} />
                          <FormControlLabel value={EUserDetailGender.FEMALE} control={<Radio />} label={FormatHelper.capitalize(EUserDetailGender.FEMALE)} />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={6} className={classes.gridItem}>
                  <FormControl fullWidth size="small">
                    <Grid container>
                      <Grid item xs={12} sm={3} lg={4}>
                        <Typography className={classes.labelLeft}>Marital Status</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={8}>
                        <CustomSelect
                          required
                          type="maritalStatus"
                          defaultValue={data.maritalStatus}
                          value={data.maritalStatus}
                          array={Object.values(EUserDetailMaritalStatus)}
                          onChange={e => handleDataChange('maritalStatus', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={6} className={classes.gridItem}>
                  <FormControl fullWidth size="small">
                    <Grid container>
                      <Grid item xs={12} sm={3} lg={4}>
                        <Typography className={classes.labelRight}>Job Title</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          placeholder="Job Title"
                          variant="outlined"
                          size="small"
                          value={data?.jobTitle}
                          required={true}
                          onChange={e => handleDataChange('jobTitle', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={6}></Grid>

                <Grid item xs={1}>
                  <Button variant="contained" size="small" className={classes.buttonOutlined} onClick={() => history.replace('/accounts')}>
                    Close
                  </Button>
                </Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={1}>
                  {saving ? (
                    <CircularProgress />
                  ) : (
                    <Button type="submit" variant="contained" size="small" style={{ float: 'right' }} className={classes.buttonContained}>
                      Next
                    </Button>
                  )}
                </Grid>
              </Grid>
            </FormGroup>
          </form>
        </Box>
      )}
    </>
  )
}
