import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import Page from 'components/Page'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { KYCFormWrapper } from './KYCFormWrapper'

export interface KYCPageStateProps {
  hide: boolean
}

export const KYCPage: React.FunctionComponent<{}> = () => {
  const [session] = useContext(SessionContext)
  const { t } = useTranslation()
  const history = useHistory()
  const [hide, setHide] = useState(false)
  const location = useLocation()
  const classes = useStyles()

  if (!session.user) return null
  const { user } = session

  useEffect(() => {
    if (location.state) {
      const pageState = location.state as KYCPageStateProps

      if (pageState.hide) {
        setHide(true)
      }
    }
  })

  return (
    <Page title={t('kyc.title')} padded>
      {!hide ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box mt={8}>
            <img alt="kyc-logo" src="/static/images/kyc-icon.png" className={classes.kycIcon} width="230px" height="230px" />
            <div style={{ marginBottom: 20 }}>
              <Typography className={classes.wording1}>
                Dear valued customers, <br /> In accordance with the regulations of Bank Indonesia, <br /> we need to verify your account before you can proceed
                with the transaction.
              </Typography>

              <Typography className={classes.wording2}>Notes: Please prepare your NIK / Passport for document upload purposes</Typography>
            </div>
            <div className={classes.btnDivContainer}>
              <Button
                variant="contained"
                className={classes.getStarted}
                onClick={() => {
                  setHide(!hide)
                }}
              >
                Get Started
              </Button>
            </div>
            <div className={classes.btnDivContainer}>
              <Button variant="contained" className={classes.goBack} onClick={() => history.goBack()}>
                Go Back
              </Button>
            </div>
          </Box>
        </Box>
      ) : (
        <KYCFormWrapper />
      )}
    </Page>
  )
}

export const useStyles = makeStyles(theme => ({
  kycIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginBottom: 20,
  },
  wording1: {
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '19.5px',
    lineHeight: '32px',
  },
  wording2: {
    marginTop: 10,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '32px',
  },
  btnDivContainer: {
    justifyContent: 'center',
    display: 'flex',
    marginBottom: 20,
  },
  goBack: {
    width: 350,
    color: '#43ADE2',
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    boxShadow: 'unset',
  },
  getStarted: {
    width: 350,
    backgroundColor: '#43ADE2',
    color: '#fff',
    fontWeight: 'bold',
  },
  inputStyle: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      display: 'none',
    },
  },
  gridItem: {
    marginBottom: 20,
    padding: 5,
  },
  labelRight: {
    marginTop: 10,
  },
  labelLeft: {
    marginTop: 10,
  },
  buttonOutlined: {
    float: 'left',
    border: '1px solid #3A6FB7',
    color: '#3A6FB7',
    fontWeight: 'bold',
    background: 'transparent',
    '&:hover': {
      backgroundColor: '#f3f6f4',
    },
  },
  buttonContained: {
    color: 'white',
    border: '1px solid #43ADE2',
    background: '#43ADE2',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#499ec8',
    },
  },
}))
