import { Box, MenuItem, Paper, Select, Step, StepLabel, Stepper, makeStyles } from '@material-ui/core'
import { EUserDetailStatus, User, UserDetail } from 'models'
import React, { useContext, useEffect, useState } from 'react'
// import { KYCAddress } from './KYCAddress'
import Loading from 'components/Loading/Loading'
import { SessionContext } from 'contexts/SessionContext'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'
import { KYCAddress } from './KYCAddress'
import { KYCPersonalData } from './KYCPersonalData'
import { KYCSubmit } from './KYCSubmit'
import { KYCUploadID } from './KYCUploadID'
// import { KYCUploadID } from './KYCUploadID'

export interface IStepContentProps {
  currentUser: User
  handleNext: () => void
  handleBack: () => void
}

export const KYCFormWrapper: React.FC<{}> = ({}) => {
  const steps = ['Personal Data', 'Address', 'Upload ID', 'Submit']
  const classes = useStyles()
  const [session] = useContext(SessionContext)

  if (!session.user) return null
  const { user } = session

  const [loading, setLoading] = useState<boolean>(true)
  const [activeStep, setActiveStep] = useState<number>(0)

  useEffect(() => {
    Api.request<UserDetail>({
      method: 'GET',
      url: `/user-details/${user?.id}`,
    }).then(resp => {
      if (resp.data as UserDetail) {
        if (resp.data.status === EUserDetailStatus.REVIEW || resp.data.status === EUserDetailStatus.APPROVED) {
          setActiveStep(4)
        }
      }

      setLoading(false)
    })
  }, [])

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <KYCPersonalData currentUser={user} handleNext={handleNext} handleBack={() => {}} />
      case 1:
        return <KYCAddress currentUser={user} handleNext={handleNext} handleBack={handleBack} />
      case 2:
        return <KYCUploadID currentUser={user} handleNext={handleNext} handleBack={handleBack} />
      case 3:
        return <KYCSubmit currentUser={user} handleNext={handleNext} handleBack={handleBack} />
      default:
        return activeStep === steps.length ? <KYCSubmit currentUser={user} handleNext={handleNext} handleBack={handleBack} /> : <></>
    }
  }

  return (
    <Box>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Stepper activeStep={activeStep} alternativeLabel className={classes.stepContainer}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel className={classes.stepLabel}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Paper style={{ height: '100%', paddingTop: '4%', paddingBottom: '4%', paddingLeft: '3%', paddingRight: '3%', borderRadius: 15 }}>
            {getStepContent(activeStep as number)}
          </Paper>
        </>
      )}
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  stepContainer: {
    backgroundColor: 'transparent',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  stepLabel: {
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: 'white',
    },
  },
}))

export const CustomSelect: React.FC<{
  value?: any
  defaultValue?: any
  array?: any[]
  onChange?: (e: any) => void
  type?: string
  disabled?: boolean
  required?: boolean
}> = props => (
  <Select
    fullWidth
    value={props.value}
    defaultValue={props.defaultValue}
    variant="outlined"
    onChange={props.onChange}
    disabled={props.disabled}
    required={props.required}
  >
    {props.array?.length == undefined || !props.array || props.array?.length === 0 ? (
      <MenuItem>No Data</MenuItem>
    ) : props.type === 'postalCode' || props.type === 'maritalStatus' ? (
      props.array?.map((v, i) => (
        <MenuItem key={i} value={v}>
          {FormatHelper.capitalize(v)}
        </MenuItem>
      ))
    ) : (
      props.array?.map((v, i) => (
        <MenuItem key={i} value={v.id}>
          {v.name ? v.name : v.category ? v.category : null}
        </MenuItem>
      ))
    )}
  </Select>
)
