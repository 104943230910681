import { Box, Button, Chip, Divider, FormControl, FormGroup, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import dayjs from 'dayjs'
import { EUserDetailStatus, User, UserDetail } from 'models'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'

interface IAccountInfoProps {
  user: User
  openAccountInfo: boolean
}

export const AccountInfo: React.FC<IAccountInfoProps> = ({ user, openAccountInfo }) => {
  const history = useHistory()

  const [userDetail, setUserDetail] = useState<UserDetail>()

  const fetchUserDetail = async () => {
    return Api.request<UserDetail>({
      method: 'GET',
      url: `/user-details/${user.id}`,
    }).then(resp => {
      if (resp.data) {
        setUserDetail(resp.data)
      }
    })
  }

  useEffect(() => {
    fetchUserDetail()
  }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Box mt={1}>
            <Typography variant="h5">Account Information</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <Chip
              label={FormatHelper.capitalize(userDetail?.status ?? 'unknown')}
              style={{
                color: 'white',
                fontWeight: 'bold',
                backgroundColor:
                  userDetail?.status === EUserDetailStatus.APPROVED
                    ? '#30A760'
                    : userDetail?.status === EUserDetailStatus.REJECTED
                    ? '#CE152A'
                    : userDetail?.status === EUserDetailStatus.REVIEW
                    ? '#F6921E'
                    : 'grey',
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {!userDetail?.status || userDetail?.status !== EUserDetailStatus.APPROVED ? (
        <Box mt={2}>
          <Paper style={{ padding: 10 }}>
            <Grid container>
              <Grid item xs={2} lg={2}>
                <img
                  src={
                    userDetail?.status === EUserDetailStatus.REJECTED
                      ? '/static/images/icon_kyc_rejected.png'
                      : userDetail?.status === EUserDetailStatus.REVIEW
                      ? '/static/images/icon_kyc_submitted.png'
                      : '/static/images/kyc-icon.png'
                  }
                  alt="user_detail_icon"
                  height={50}
                  width={50}
                  style={{ margin: 'auto', display: 'block' }}
                />
              </Grid>
              <Grid item xs={10} lg={8}>
                <Typography>
                  {userDetail?.status === EUserDetailStatus.REJECTED ? (
                    <>
                      Your data has been <b style={{ color: '#CE152A' }}>{FormatHelper.capitalize(userDetail?.status)},</b>
                      <br />
                      Reason : <b>{userDetail?.notes}</b>
                    </>
                  ) : userDetail?.status === EUserDetailStatus.REVIEW ? (
                    <>
                      Your data has been recorded in our system, <br /> Please wait, our team will verify your data and will notify to you as soon as possible.
                    </>
                  ) : (
                    <>
                      Dear Valued Customer, <br />
                      we need to verify your account as compliance requirement to ensure your transaction are secure
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Box display="flex" justifyContent="center" p={1}>
                  {userDetail?.status === EUserDetailStatus.REJECTED ? (
                    <Button fullWidth variant="contained" color="primary" style={{ color: 'white' }} onClick={() => history.replace('/kyc', { hide: true })}>
                      Resubmit Data
                    </Button>
                  ) : userDetail?.status === EUserDetailStatus.REVIEW ? (
                    <Typography style={{ color: 'orange', fontWeight: 'bold' }}>Waiting for review</Typography>
                  ) : (
                    <Button fullWidth variant="contained" color="primary" style={{ color: 'white' }} onClick={() => history.replace('/kyc', { hide: true })}>
                      Get Started
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      ) : null}
      {openAccountInfo ? <AccountInfoForm user={user} userDetail={userDetail} /> : null}
    </>
  )
}

interface IAccountInfoFormProps {
  user: User
  userDetail?: UserDetail
}

const AccountInfoForm: React.FC<IAccountInfoFormProps> = ({ user, userDetail }) => {
  const [data, setData] = useState<UserDetail | undefined>(userDetail)

  const onSubmit = () => {}

  return (
    <Box mt={3}>
      <Divider />

      <form onSubmit={onSubmit}>
        <FormGroup>
          <Box mt={2}>
            <Grid container>
              <Grid item xs={2}>
                <Box mt={2}>
                  <Typography>
                    <b>Personal Data</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box p={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Nationality"
                          placeholder="Nationality"
                          variant="outlined"
                          size="small"
                          value={data?.nationality}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="NIK / Passport"
                          placeholder="NIK / Passport"
                          variant="outlined"
                          size="small"
                          value={data?.identityNumber}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Full Name"
                          placeholder="Full Name"
                          variant="outlined"
                          size="small"
                          value={data?.user?.name}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Phone Number"
                          placeholder="Phone Number"
                          variant="outlined"
                          size="small"
                          value={data?.user?.mobile}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Gender"
                          placeholder="Gender"
                          variant="outlined"
                          size="small"
                          value={data?.gender}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box p={2}>
                      <Grid container>
                        <Grid item xs={12}>
                          <FormControl fullWidth required>
                            <TextField
                              disabled
                              label="City of Birth"
                              placeholder="City of Birth"
                              variant="outlined"
                              size="small"
                              value={data?.cityOfBirth}
                              required
                              fullWidth
                              onChange={e => {}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          <FormControl fullWidth required>
                            <TextField
                              disabled
                              label="Date Of Birth"
                              placeholder="Date Of Birth"
                              variant="outlined"
                              size="small"
                              value={dayjs(data?.dateOfBirth).format('DD/MM/YYYY')}
                              required
                              fullWidth
                              onChange={e => {}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Grid container>
              <Grid item xs={2}>
                <Box mt={2}>
                  <Typography>
                    <b>Address Info</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Box>
                  <Grid container>
                    <Grid item xs={4} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Province"
                          placeholder="Province"
                          variant="outlined"
                          size="small"
                          value={data?.identityAddress?.province?.name}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="City"
                          placeholder="City"
                          variant="outlined"
                          size="small"
                          value={data?.identityAddress?.city?.name}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="District"
                          placeholder="District"
                          variant="outlined"
                          size="small"
                          value={data?.identityAddress?.district?.name}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={9} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Address"
                          placeholder="Address"
                          variant="outlined"
                          size="small"
                          value={data?.identityAddress?.detail}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Postal Code"
                          placeholder="Postal Code"
                          variant="outlined"
                          size="small"
                          value={data?.identityAddress?.postalCode}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box mt={2} mb={1} ml={2}>
                    <Typography variant="caption">Current Address</Typography>
                  </Box>

                  <Grid container>
                    <Grid item xs={4} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Province"
                          placeholder="Province"
                          variant="outlined"
                          size="small"
                          value={data?.currentAddress?.province?.name}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="City"
                          placeholder="City"
                          variant="outlined"
                          size="small"
                          value={data?.currentAddress?.city?.name}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="District"
                          placeholder="District"
                          variant="outlined"
                          size="small"
                          value={data?.currentAddress?.district?.name}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={9} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Address"
                          placeholder="Address"
                          variant="outlined"
                          size="small"
                          value={data?.currentAddress?.detail}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} style={{ padding: 15 }}>
                      <FormControl fullWidth required>
                        <TextField
                          disabled
                          label="Postal Code"
                          placeholder="Postal Code"
                          variant="outlined"
                          size="small"
                          value={data?.currentAddress?.postalCode}
                          required
                          fullWidth
                          onChange={e => {}}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Grid container>
              <Grid item xs={2}>
                <Box mt={2}>
                  <Typography>
                    <b>ID Document</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box p={2}>
                      <Grid container>
                        <Grid item xs={12}>
                          <FormControl fullWidth required>
                            <TextField
                              disabled
                              label="KTP"
                              placeholder="KTP"
                              variant="outlined"
                              size="small"
                              value={data?.identityFile?.url}
                              required
                              fullWidth
                              onChange={e => {}}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton size="small">
                                      <Visibility />
                                    </IconButton>
                                    Preview
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box p={2}>
                          <FormControl fullWidth required>
                            <TextField
                              disabled
                              label="Photo Selfie"
                              placeholder="Photo Selfie"
                              variant="outlined"
                              size="small"
                              value={data?.selfieFile?.url}
                              required
                              fullWidth
                              onChange={e => {}}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton size="small">
                                      <Visibility />
                                    </IconButton>
                                    Preview
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box p={2}>
                          <FormControl fullWidth required>
                            <TextField
                              disabled
                              label="Taxpayer Identification"
                              placeholder="Taxpayer Identification"
                              variant="outlined"
                              size="small"
                              value={data?.npwpFile?.url}
                              required
                              fullWidth
                              onChange={e => {}}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton size="small">
                                      <Visibility />
                                    </IconButton>
                                    Preview
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </FormGroup>
      </form>
    </Box>
  )
}
