import { City } from './City'
import { Province } from './Province'

export class District {
  constructor(
    public id: number,
    public cityId: number,
    public provinceId: number,
    public name: string,
    public postalCodes: string[],
    public city?: City,
    public province?: Province,
  ) {}
}
