import React, { useState, useContext, useEffect } from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Account } from 'models'
import LoadingComponent from 'components/LoadingComponent'
// @ts-ignore
import QRCode from 'qrcode.react'
import { AppContext } from 'contexts'
import Api from 'utils/Api'
import { SessionContext } from 'contexts/SessionContext'

interface ReceiveContentProps {
  account: Account
}

const ReceiveContent: React.FunctionComponent<ReceiveContentProps> = ({ account }) => {
  const { t } = useTranslation('wallet')
  const [session] = useContext(SessionContext)

  const [loading, setLoading] = useState(false)
  const [displayRetry, setDisplayRetry] = useState(false)
  const [depositAddress, setDepositAddress] = useState<null | string>(null)

  const getDepositAddress = (isRetry = false) => {
    setLoading(true)
    Api.request<{ address: string }>({
      method: 'GET',
      url: '/account/deposit_address/' + account.currency,
    })
      .then(res => {
        setLoading(false)
        if (res.data && res.data.address) {
          setDepositAddress(res.data.address)
        } else {
          if (!isRetry) {
            if (account.currencyId === 'BTC') {
              setTimeout(() => getDepositAddress(true), 3000)
            }
          } else {
            setDisplayRetry(true)
          }
        }
      })
      .catch(() => {
        setLoading(false)
        setDepositAddress(null)

        // retry
        if (!isRetry) {
          setTimeout(() => getDepositAddress(true), 3000)
        } else {
          setDisplayRetry(true)
        }
      })
  }

  useEffect(getDepositAddress, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <div>
          <div style={{ textAlign: 'center', margin: '15px auto' }}>
            {depositAddress !== null ? (
              <div>
                <QRCode value={depositAddress} />
                <div
                  style={{
                    // minWidth: '100%',
                    marginTop: 15,
                    padding: '10px 15px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  {depositAddress}
                </div>
              </div>
            ) : (
              <div>
                <div style={{ minHeight: 160 }}>{loading && <LoadingComponent />}</div>
                <Typography variant="body1">{t('generateWalletAddress') + '..'}</Typography>
                {displayRetry && (
                  <Button
                    style={{ marginTop: 20 }}
                    variant="text"
                    onClick={() => {
                      getDepositAddress(false)
                    }}
                  >
                    {t('retry')}
                  </Button>
                )}
              </div>
            )}
          </div>

          <div>
            <b>{t('note.title')}:</b>
            <ul>
              <li>{t('note.li1')}</li>
              <li>
                {t('note.li21')} {account.currency} {t('note.li22')}
              </li>
              <li>
                {t('note.li31')} <b>{account.currencyId}</b> {t('note.li32')}
              </li>
            </ul>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export { ReceiveContent }
