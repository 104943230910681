import { Box, Button, CircularProgress, FormGroup, Grid, IconButton, Link, Paper, Tooltip, Typography } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import Loading from 'components/Loading/Loading'
import { AppContext } from 'contexts'
import { SessionContext } from 'contexts/SessionContext'
import lodash from 'lodash'
import { UserDetail } from 'models'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Api from 'utils/Api'
import { IStepContentProps } from './KYCFormWrapper'
import { useStyles } from './KYCPage'

export const KYCUploadID: React.FC<IStepContentProps> = ({ currentUser, handleBack, handleNext }) => {
  const { handleSubmit } = useForm()
  const { handleError } = useContext(AppContext)
  const history = useHistory()
  const classes = useStyles()

  let uploadKTPRef = useRef<HTMLInputElement>(null)
  let uploadPhotoSelfieRef = useRef<HTMLInputElement>(null)
  let uploadNPWPRef = useRef<HTMLInputElement>(null)

  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [data, setData] = useState<Partial<UserDetail>>({
    id: undefined,
    userId: undefined,
    identityFileId: undefined,
    selfieFileId: undefined,
    npwpFileId: undefined,
    identityFile: undefined,
    selfieFile: undefined,
    npwpFile: undefined,
  })
  const [fileInfo, setFileInfo] = useState<{
    identityFileUrl?: string
    identityFileName?: string
    selfieFileUrl?: string
    selfieFileName?: string
    npwpFileUrl?: string
    npwpFileName?: string
  }>()
  const [session, dispatch] = useContext(SessionContext)

  useEffect(() => {
    Api.request<UserDetail>({
      method: 'GET',
      url: `/user-details/${currentUser?.id}`,
    }).then(resp => {
      if (resp.data as UserDetail) {
        setData({
          id: resp.data.id,
          userId: resp.data.userId,
          identityFileId: resp.data.identityFileId,
          selfieFileId: resp.data.selfieFileId,
          npwpFileId: resp.data.npwpFileId,
          identityFile: resp.data.identityFile,
          selfieFile: resp.data.selfieFile,
          npwpFile: resp.data.npwpFile,
        })
      }

      setLoading(false)
    })
  }, [])

  const handleDataChange = (key: keyof UserDetail, value: any) => {
    setData({
      ...data,
      [key]: value,
    })
  }

  const onSubmit = () => {
    setSaving(true)

    const payload = {
      ...lodash(data).omitBy(lodash.isUndefined).omitBy(lodash.isNull).omit(data, 'identityFile', 'selfieFile', 'npwpFile').value(),
    }

    Api.patch(`/user-details/${data.id}`, payload)
      .then(() => {
        Api.patch(`/user-details/${data.id}/review/${currentUser.id}`)
          .then(() => {
            dispatch({ type: 'FETCH_USER_ME' })
            setSaving(false)
            handleNext()
            handleNext()
          })
          .catch(err => {
            setSaving(false)
            handleError(err)
          })
      })
      .catch(err => {
        setSaving(false)
        handleError(err)
      })
  }

  const onUpload = async (key: keyof UserDetail, event: React.ChangeEvent<HTMLInputElement>) => {
    let file: File | null = null

    if (event.target.files && event.target.files[0]) {
      file = event.target.files[0]

      if (file) {
        const formData = new FormData()
        formData.append('file', file)

        Api.post('/files', formData)
          .then(resp => {
            handleDataChange(key, resp.data.files[0].id)

            if (key === 'identityFileId') {
              setFileInfo({ ...fileInfo, identityFileUrl: resp.data.files[0].url, identityFileName: resp.data.files[0].filename })
            } else if (key === 'selfieFileId') {
              setFileInfo({ ...fileInfo, selfieFileUrl: resp.data.files[0].url, selfieFileName: resp.data.files[0].filename })
            } else if (key === 'npwpFileId') {
              setFileInfo({ ...fileInfo, npwpFileUrl: resp.data.files[0].url, npwpFileName: resp.data.files[0].filename })
            }
          })
          .catch(err => {
            handleError(err)
          })
      }
    }
  }

  function getFileNameFromUrl(url: string) {
    // Get the last part of the URL after the last slash
    const parts = url.split('/')
    const filenameWithExtension = parts[parts.length - 1]

    return filenameWithExtension
  }

  useEffect(() => {
    console.log('[log] data: ', data)
  }, [data])

  return (
    <Box>
      <Typography variant="h4" style={{ fontWeight: 'bold' }}>
        Upload your ID Card (E-KTP / Passport)
      </Typography>
      {loading ? (
        <Loading />
      ) : (
        <Box mt={5}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormGroup>
              <Grid container direction="row">
                <Grid item xs={12} lg={8}>
                  <Paper style={{ padding: 5 }}>
                    <Grid container direction="row">
                      <Grid style={{ padding: 5 }} item xs={3} sm={2}>
                        <img
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          src={
                            fileInfo?.identityFileUrl
                              ? fileInfo?.identityFileUrl
                              : data.identityFile?.url
                              ? data.identityFile?.url
                              : '/static/images/icon_upload_id.png'
                          }
                          alt="icon_upload_id"
                          width={'50%'}
                          height={'100%'}
                        />
                      </Grid>
                      <Grid item xs={9} sm={8}>
                        <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
                          <Tooltip title="Preview image">
                            {fileInfo?.identityFileUrl || data.identityFile?.url ? (
                              <Link
                                href={fileInfo?.identityFileUrl ? fileInfo.identityFileUrl : data.identityFile?.url ? data.identityFile.url : undefined}
                                target="_blank"
                                style={{ fontSize: 12, fontStyle: 'normal', textDecoration: 'underline' }}
                              >
                                <IconButton size="small" style={{ marginRight: 5 }}>
                                  <Visibility />
                                </IconButton>
                                {fileInfo?.identityFileName
                                  ? fileInfo.identityFileName
                                  : data.identityFile?.url
                                  ? getFileNameFromUrl(data.identityFile.url)
                                  : 'Upload your ID Card (E-KTP / Passport)'}
                              </Link>
                            ) : (
                              <Typography style={{ fontSize: 12, fontStyle: 'normal' }}>Upload your ID Card (E-KTP / Passport)</Typography>
                            )}
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid style={{ padding: 5 }} item xs={12} sm={2}>
                        <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
                          <Button color="primary" variant="contained" style={{ minHeight: 23, maxHeight: 23 }} onClick={() => uploadKTPRef.current?.click()}>
                            {data.identityFile?.url ? 'Change' : fileInfo?.identityFileName ? 'Change' : 'Upload'}
                          </Button>
                          <input ref={uploadKTPRef} accept="image/png,image/jpg,image/jpeg" type="file" onChange={e => onUpload('identityFileId', e)} hidden />
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} lg={8} style={{ marginTop: 20, marginBottom: 20 }}>
                  <Paper style={{ padding: 5 }}>
                    <Grid container direction="row">
                      <Grid style={{ padding: 5 }} item xs={3} sm={2}>
                        <img
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          src={fileInfo?.npwpFileUrl ? fileInfo?.npwpFileUrl : data.npwpFile?.url ? data.npwpFile?.url : '/static/images/icon_upload_id.png'}
                          alt="icon_upload_id"
                          width={'50%'}
                          height={'100%'}
                        />
                      </Grid>
                      <Grid item xs={9} sm={8}>
                        <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
                          <Tooltip title="Preview image">
                            {fileInfo?.npwpFileUrl || data.npwpFile?.url ? (
                              <Link
                                href={fileInfo?.npwpFileUrl ? fileInfo.npwpFileUrl : data.npwpFile?.url ? data.npwpFile.url : undefined}
                                target="_blank"
                                style={{ fontSize: 12, fontStyle: 'normal', textDecoration: 'underline' }}
                              >
                                <IconButton size="small" style={{ marginRight: 5 }}>
                                  <Visibility />
                                </IconButton>
                                {fileInfo?.npwpFileName
                                  ? fileInfo.npwpFileName
                                  : data.npwpFile?.url
                                  ? getFileNameFromUrl(data.npwpFile.url)
                                  : 'Upload your Taxpayer Identification'}
                              </Link>
                            ) : (
                              <Typography style={{ fontSize: 12, fontStyle: 'normal' }}>Upload your Taxpayer Identification</Typography>
                            )}
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid style={{ padding: 5 }} item xs={12} sm={2}>
                        <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
                          <Button color="primary" variant="contained" style={{ minHeight: 23, maxHeight: 23 }} onClick={() => uploadNPWPRef.current?.click()}>
                            {data.npwpFile?.url ? 'Change' : fileInfo?.npwpFileName ? 'Change' : 'Upload'}
                          </Button>
                          <input ref={uploadNPWPRef} accept="image/png,image/jpg,image/jpeg" type="file" onChange={e => onUpload('npwpFileId', e)} hidden />
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} lg={8} style={{ marginTop: 20, marginBottom: 20 }}>
                  <Paper style={{ padding: 5 }}>
                    <Grid container direction="row">
                      <Grid style={{ padding: 5 }} item xs={3} sm={2}>
                        <img
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          src={
                            fileInfo?.selfieFileUrl
                              ? fileInfo?.selfieFileUrl
                              : data.selfieFile?.url
                              ? data.selfieFile?.url
                              : '/static/images/icon_upload_selfie.png'
                          }
                          alt="icon_upload_selfie"
                          width={'50%'}
                          height={'100%'}
                        />
                      </Grid>
                      <Grid item xs={9} sm={8}>
                        <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
                          <Tooltip title="Click to preview image">
                            {fileInfo?.selfieFileName || data.selfieFile?.url ? (
                              <Link
                                href={fileInfo?.selfieFileUrl ? fileInfo.selfieFileUrl : data.selfieFile?.url ? data.selfieFile.url : undefined}
                                target="_blank"
                                style={{ fontSize: 12, fontStyle: 'normal', textDecoration: 'underline' }}
                              >
                                <IconButton size="small" style={{ marginRight: 5 }}>
                                  <Visibility />
                                </IconButton>
                                {fileInfo?.selfieFileName
                                  ? fileInfo.selfieFileName
                                  : data.selfieFile?.url
                                  ? getFileNameFromUrl(data.selfieFile.url)
                                  : 'Upload your ID Card (E-KTP / Passport)'}
                              </Link>
                            ) : (
                              <Typography style={{ fontSize: 12, fontStyle: 'normal' }}>Upload your ID Card (E-KTP / Passport)</Typography>
                            )}
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid style={{ padding: 5 }} item xs={12} sm={2}>
                        <Box height="100%" display="flex" justifyContent="center" flexDirection="column">
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ minHeight: 23, maxHeight: 23 }}
                            onClick={() => uploadPhotoSelfieRef.current?.click()}
                          >
                            {data.selfieFile?.url ? 'Change' : fileInfo?.selfieFileName ? 'Change' : 'Upload'}
                          </Button>
                          <input
                            ref={uploadPhotoSelfieRef}
                            accept="image/png,image/jpg,image/jpeg"
                            type="file"
                            onChange={e => onUpload('selfieFileId', e)}
                            hidden
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={3} sm={2}>
                  <Button variant="contained" size="small" className={classes.buttonOutlined} onClick={() => history.replace('/accounts')}>
                    Close
                  </Button>
                </Grid>
                <Grid item xs={3} sm={6}></Grid>
                <Grid item xs={6} sm={4}>
                  {saving ? (
                    <CircularProgress />
                  ) : (
                    <Grid container>
                      <Grid item xs={6} sm={8} lg={9}>
                        <Box display="flex" justifyContent="flex-end">
                          <Button variant="contained" size="small" className={classes.buttonOutlined} onClick={handleBack}>
                            Previous
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={4} lg={3}>
                        <Box display="flex" justifyContent="flex-end">
                          <Button type="submit" variant="contained" size="small" className={classes.buttonContained}>
                            Next
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </FormGroup>
          </form>
        </Box>
      )}
    </Box>
  )
}
